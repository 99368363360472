<template>
    <div>
        <div class="order-report ec-detail ec-card" v-if="reportPublished">
            <a-spin :spinning="loading">
                <!-- 基本信息 -->
                <div class="card-title">
                    <span class="card-title-real">{{ $t("order.report.info") }}</span>
                </div>

                <div class="card-body">
                    <a-descriptions bordered :column="2" class="order-report-base-info">
                        <a-descriptions-item :label="$t('backCheck.report.client')">
                            {{ reportContent.customerName }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.cname')">
                            {{ reportContent.candidateName }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.number')">
                            {{ reportContent.reportSerialNo }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.detail.sex')">
                            <span v-if="this.$store.getters.isChinese">
                                {{ reportContent.candidateGender }}
                            </span>

                            <span v-else>
                                {{ reportContent.candidateGenderMutipleLang }}
                            </span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.report.idCard')">
                            {{ reportContent.candidateIdCardNo }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('present.info.birth')">
                            {{ reportContent.candidateBirthday }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.status')">
                            <span v-if="this.$store.getters.isChinese">
                                {{ reportContent.reportStatus }}
                            </span>

                            <span v-else>
                                {{ reportContent.reportStatusMutipleLang }}
                            </span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.level')">
                            <img style="width: 18px;" :src="shieldImages[reportContent.reportLevel]"
                                :alt="reportContent.shieldCode" />
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.report.submitDate')">
                            {{ reportContent.submissionDate }}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>

                <!-- 概述 -->
                <div class="card-title">
                    <span class="card-title-real">{{ $t("order.report.view") }}</span>
                </div>

                <div class="card-body">
                    概述内容
                </div>

                <!--详情-->
                <div>
                    <div class="card-title">
                        <span class="card-title-real">产品名称</span>
                    </div>

                    <div class="card-body">
                        模块内容
                    </div>
                </div>
            </a-spin>
        </div>
        <div v-else style="display: flex; justify-content: center;">
            <div class="not-found">
                <div class="not-found-img"></div>
                <div class="not-found-title"
                    style="font-weight: bold;  font-size: 32px;  margin-top: 40px;  margin-bottom: 20px;">{{
                        errorMessage }}</div>
                <div class="not-found-btn" style="margin-top: 40px">
                    <a-button type="primary" ghost block @click="goBack(1)">返回</a-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { getReportContent } from './api';

export default {
    name: "RcReport",
    data() {
        return {
            reportPublished: true,
            errorMessage: "",
            loading: false,
            reportContent: {},
            shieldImages: {}
        }
    },
    methods: {
        goBack(type = 0) {
            if (type) {
                this.$router.go(-1);
            } else {
                this.$router.replace("/");
            }
        },

        async collectImage() {
            if (!this.reportContent) {
                return;
            }

            //报告等级图片
            if (this.reportContent.reportLevel) {
                const reportImageData = await import(`@/assets/images/dp/${this.reportContent.reportLevel}`);

                if (reportImageData && reportImageData.default) {
                    this.shieldImages[this.reportContent.reportLevel] = reportImageData.default;
                }
            }

            //模块等级图片
            if (this.reportContent.summary) {
                this.reportContent.summary.forEach(async element => {
                    if (element.shieldImg) {
                        const moduleImageData = await import(`@/assets/images/dp/${element.shieldImg}`);

                        if (moduleImageData && moduleImageData.default) {
                            this.shieldImages[element.shieldImg] = moduleImageData.default;
                        }
                    }
                });
            }
        },

        async getReport() {
            this.loading = true;
            const { id } = this.$route.params;

            if (!id || isNaN(id)) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            const taskNo = Number.parseInt(id);

            if (taskNo < 1) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            const response = await getReportContent(taskNo);
            console.log(response);

            if (!response.dataOriginal && !response.data.success) {
                this.errorMessage = response.data.msg;
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: response.data.msg,
                });
                this.loading = false;
                return;
            }

            if (!response.data) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            this.reportContent = response.data;

            await this.collectImage()

            this.loading = false;
            this.reportPublished = true;
        }
    },
    async mounted() {
        await this.getReport();
    }
}
</script>

<style lang="less" scoped>
.order-report {
    background: #fff;
    padding-left: 0;
    padding-right: 0;

    .order-report-base-info {
        /deep/.ant-descriptions-view {
            border-radius: 8px;
        }
    }

    .order-report-group-table-sub {
        /deep/ .ant-table-bordered .ant-table-body>table {
            border-top: 1px solid #e8e8e8 !important;
            border-left: 1px solid #e8e8e8 !important;
        }
    }

    .order-report-group-table {
        /deep/ .ant-table-tbody .ant-table-row:last-child {
            td:first-child {
                border-bottom-left-radius: 8px;
            }

            td:last-child {
                border-bottom-right-radius: 8px;
            }
        }

        /deep/ .ant-table-bordered .ant-table-title {
            font-weight: 600 !important;
        }

        /deep/ .ant-table-tbody>tr:nth-child(even)>td {
            background: unset;
        }

        /deep/ .ant-table-thead th {
            background: #f2f5fb;
        }
    }

    .order-report-group {
        margin: 15px;
    }

    .card-title {
        color: @font-color;
        font-size: 18px;
        font-weight: bold;
        .relative();

        &-real {
            margin-left: 15px;
        }

        &:before {
            display: block;
            content: " ";
            width: 3px;
            height: 18px;
            background: @primary-color;
            border-radius: 8px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }
    }

    /deep/.list-card {
        margin-bottom: @ec-gutter;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .base-item {
        .base-title {
            width: 35%;
            text-align: right;
            padding: @ec-gutter @ec-gutter * 2;
            background: #f5f5f5;
            border: 1px solid #e9e9e9;
            border-top: none;
            .left();
            min-height: 42px;
        }

        .base-value {
            width: 65%;
            .left();
            border-bottom: 1px solid #e9e9e9;
            .no-wrap();
            padding: @ec-gutter @ec-gutter * 2;
            min-height: 42px;
        }
    }

    .report-btn {
        margin-top: 60px;
        .btn-group(center);
    }

    .report-table {
        /deep/.ant-table-thead {
            background: #f5f5f5;
        }
    }

    .shield-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        vertical-align: middle;
    }
}

.card-body {
    padding: @ec-gutter20;
}

/deep/.ant-col {
    &:first-child,
    &:nth-child(2) {
        .base-item {
            border-top: 1px solid #e9e9e9;
        }
    }

    &:nth-child(2n) {
        .base-item {
            .base-value {
                border-right: 1px solid #e9e9e9;
            }
        }
    }
}
</style>
