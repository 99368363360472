// 批量导入背调类型
export const CREATE_TYPE = {
  // 传统工作委托
  TRADITION: "0",
  // 易查宝快速核验委托
  QUICK: "1",
};

export const ReportLevel = [
  {
    id: 1,
    dictType: "report_level",
    value: 2209,
    dictCode: 2209,
    label: "红盾",
    dictName: "红盾",
  },
  {
    id: 2,
    dictType: "report_level",
    value: 2210,
    dictCode: 2210,
    label: "黄盾",
    dictName: "黄盾",
  },
  {
    id: 3,
    dictType: "report_level",
    value: 2211,
    dictCode: 2211,
    label: "绿盾",
    dictName: "绿盾",
  },
];

export const CssTaskProgress = [
  {
    id: 1,
    dictType: "css_task_progress",
    value: 567,
    dictCode: 567,
    label: "客户委托",
    dictName: "客户委托",
  },
  {
    id: 2,
    dictType: "css_task_progress",
    value: 568,
    dictCode: 568,
    label: "资料收集",
    dictName: "资料收集",
  },
  {
    id: 3,
    dictType: "css_task_progress",
    value: 569,
    dictCode: 569,
    label: "资料审核",
    dictName: "资料审核",
  },
  {
    id: 4,
    dictType: "css_task_progress",
    value: 570,
    dictCode: 570,
    label: "报告制作",
    dictName: "报告制作",
  },
  {
    id: 5,
    dictType: "css_task_progress",
    value: 571,
    dictCode: 571,
    label: "报告审核",
    dictName: "报告审核",
  },
  {
    id: 6,
    dictType: "css_task_progress",
    value: 572,
    dictCode: 572,
    label: "阶段报告",
    dictName: "阶段报告",
  },
  {
    id: 7,
    dictType: "css_task_progress",
    value: 573,
    dictCode: 573,
    label: "终版报告",
    dictName: "终版报告",
  },
  {
    id: 8,
    dictType: "css_task_progress",
    value: 574,
    dictCode: 574,
    label: "核算完成",
    dictName: "核算完成",
  },
  {
    id: 9,
    dictType: "css_task_progress",
    value: 641,
    dictCode: 641,
    label: "候选人身份核实未通过",
    dictName: "候选人身份核实未通过",
  },
];

// 报告字段翻转
export const ReportKeys = {
  CaseNum: "执行编号",
  CaseNumber: "执行编号",
  Province: "省份",
  CourtName: "法院",
  Court: "法院",
  FulfillmentStatus: "执行结果",
  Reason: "案由概类",
  PublishDate: "发布日期",
  CaseDate: "案件日期",
  AuthenticationContent: "认证内容",
  submitOrgName: "认证机构",
  certificateID: "证书编号",
  BanZhengRiQi: "认证时间",
  Year: "立案年份",
  sortTimeString: "时间",
  title: "标题",
  body: "正文概要",
  ryname: "查询人姓名",
  entname: "企业(机构)名称",
  regno: "注册号",
  regcap: "注册资本(万元)",
  regcapcur: "注册资本币种",
  entstatus: "企业状态",
  subconam: "认缴出资额(万)",
  position: "职位",
  Role: "涉案角色",
  Region: "涉案地区",
  MoneySum: "涉案金额",
  CaseCloseResult: "结案方式",
  CivilCase: "民事案件",
  CriminalCase: "刑事案件",
  AdministrativeCase: "行政案件",
  EnforcementCase: "执行案件",
  ForcedLiquidationAndBankruptcy: "强制清算与破产",
  Accuser: "原告",
  Defendant: "被告",
  TheThirdMan: "第三人",
};
