import api from "@/utils/fetch";

/**
 * 单个文件下载
 * @param params
 * @param {string} params.fileNo
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function singleFileDownload(params) {
  return await api.download("/commonMgt/download", params, { method: "GET" });
}

/**fileNos
 * 批量下载文件
 * @param params
 * @param {string} params.fileNos
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function downloadZip(params) {
  return api.download("/commonMgt/downloadZip", params, { method: "GET" });
}
