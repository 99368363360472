<template>
  <div>
    <a-modal :title="title" :visible="isHrModal" @cancel="cancel" :maskClosable="false" @ok="confirm" destroyOnClose>
      <template v-if="!isExport">
        <a-form-model :model="form" :rules="rules" ref="form" layout="inline">
          <a-form-model-item :label="$t('backCheck.sms')" prop="smscode">
            <a-input size="large" v-model="form.smscode" :placeholder="$t('user.mobileCode.placeholder')"
              :maxLength="6">
              <div slot="suffix">
                <span v-if="sending" class="disabled-color">{{
                  $t("backCheck.sendText", { second: seconds })
                }}</span>
                <span v-else @click="sendSms" class="ec-color-primary">{{
                  sendText
                }}</span>
              </div>
            </a-input>
          </a-form-model-item>
        </a-form-model>
      </template>
      <template v-else>
        <a-button class="export-button" type="primary" @click="exportFile" :loading="loading">
          {{ $t("backCheck.downFile") }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { exportSendCode } from "@/pages/home/api";
import { showMsg } from "@/utils";
import { exportOrderListPage } from "@/pages/home/order/api";
import { langMixin } from "@/mixins";
import moment from "moment";

export default {
  name: "SMSModal",
  props: {
    isHrModal: Boolean,
    params: Object
  },
  mixins: [langMixin],
  computed: {
    title() {
      return this.isExport
        ? this.$t("backCheck.exporting")
        : this.$t("backCheck.hrCheck");
    }
  },
  data() {
    return {
      form: {
        smscode: ""
      },
      rules: {
        smscode: [
          {
            required: true,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("user.mobileCode.placeholder"),
            trigger: "blur"
          }
        ]
      },
      sending: false,
      timer: "",
      seconds: 60,
      second: 60,
      sendText: this.$t("backCheck.smscode"),
      isExport: false,
      loading: false
    };
  },
  methods: {
    cancel() {
      this.form.smscode = "";
      this.isExport = false;
      this.sending = false;
      this.seconds = this.second;
      clearInterval(this.timer);
      this.$emit("update:isHrModal", false);
    },
    confirm() {
      if (this.isExport) {
        this.cancel();
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.exportFile();
          }
        });
      }
    },
    async sendSms() {
      const res = await exportSendCode();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.clearTimer();
      let successMsg = this.$t("backCheck.smsuccess");
      if (res.data) {
        const minutes = moment(+res.data * 1000).format("HH:mm");
        successMsg += `, ${this.$t("backCheck.smsExpired", { min: minutes })}`;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: successMsg
      });
      this.sending = true;
      this.timer = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          this.clearTimer();
          this.sending = false;
          this.seconds = this.second;
          this.sendText = this.$t("backCheck.resetsms");
        }
      }, 1000);
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    async exportFile() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let params = {
        lang: this.lang,
        code: this.form.smscode,
        targetUserId: this.$store.state.user.info.userId,
        ...this.params
      };
      const res = await exportOrderListPage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: this.$t("backCheck.smsInvalid")
        });
        this.loading = false;
        this.isExport = false;
        return;
      }
      this.isExport = true;
      this.loading = false;
      // 导出成功 关闭弹窗 防止再次点击下载
      // 因为手机验证码只能用一次
      this.cancel();
    }
  },
  beforeDestroy() {
    this.clearTimer();
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-input-suffix {
  color: @primary-color;
  .pointer();
}

.disabled-color {
  color: @title-color;
}

.export-button {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
