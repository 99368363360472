import api from "@/utils/fetch";
// 数据简报
export async function getBrief(params) {
  return await api.get("/overview/bulletin", params);
}
// 盾牌分布统计
export async function getShield(params) {
  return await api.get("/overview/shieldCountByMonth", params);
}

// 任务订单统计(近30天委托单进度跟踪数量)
export function taskOrderCount(params) {
  return api.get("/overview/taskOrderCount", params);
}

// 报告统计(近30天阶段报告数量（份）
export function reportCount(params) {
  return api.get("/overview/reportCount", params);
}

// 候选人招聘风险分析
export function zhaopin(params) {
  return api.get("/overview/zhaopin", params);
}

//委托单量
export async function getTemplateCount(params) {
  return await api.get("/overview/templateCount", params);
}

/**
 * 任务更新通知
 * @param {Object=} params
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function messageList(params) {
  return api.get("/overview/messageList", params);
}

// 任务单详情
export function getTaskDetail(params) {
  return api.get("/commonMgt/taskDetails", params);
}

// 委托单量趋势
export function getOrderTrend(params) {
  return api.get("/overview/taskCountByYear", params);
}
