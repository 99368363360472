<template>
  <div class="candidate-list">
    <div v-for="(i, idx) in tabList" :key="i.key">
      <!-- 筛选区 -->
      <ListFilter :form-data.sync="currentFilterData" :form-list="i.filterList" :title="''"
        class="list-filter can-manage-list-filter" @reset="() => onResetFilter(idx)"
        @filter="form => onFilter(i.key, form)">
        <!--#region 所属机构-->
        <a-form-model-item slot="companyId" :label="$t('bill.filter.org')">
          <a-select :getPopupContainer="trigger => trigger.parentNode" size="large"
            :value="candidateTabFilters[idx].filters.targetCompanyId" @change="companyId =>
              onFilter(
                i.key,
                { ...currentFilterData, targetCompanyId: companyId },
                true
              )
              " :placeholder="$t('bill.filter.org')" allowClear>
            <a-select-option v-for="op in companyList" :key="op.companyId" :value="op.companyId">
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 委托HR-->
        <a-form-model-item slot="targetUserId" :label="$t('order.filter.hr')">
          <a-select show-search :filter-option="(input, option) => {
            return (
              option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
          }" :getPopupContainer="trigger => trigger.parentNode" size="large"
            :value="candidateTabFilters[idx].filters.targetUserId" @change="targetUserId =>
              onFilter(i.key, { ...currentFilterData, targetUserId }, true)
              " :placeholder="$t('order.filter.hr')" allowClear>
            <a-select-option v-for="op in hrList" :key="op.id" :value="op.id">
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 记录来源-->
        <a-form-model-item slot="registrationSource" :label="$t('backCheck.registrationSource')">
          <a-select :getPopupContainer="trigger => trigger.parentNode" size="large"
            :value="candidateTabFilters[idx].filters.registrationSource" @change="registrationSource =>
              onFilter(
                i.key,
                { ...currentFilterData, registrationSource },
                true
              )
              " :placeholder="`${$t('select')}${$t('backCheck.registrationSource')}`
                " allowClear>
            <a-select-option v-for="op in registrationSourceList" :key="op.key" :value="op.key">
              {{ op.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
        <!--#region 报告版本:已废弃-->
        <a-form-model-item slot="packageName" :label="$t('backCheck.merged.packageName')">
          <a-select :getPopupContainer="trigger => trigger.parentNode" size="large"
            :value="candidateTabFilters[idx].filters.packageName" @change="packageName =>
              onFilter(i.key, { ...currentFilterData, packageName }, true)
              " :placeholder="$t('backCheck.merged.packageName')" allowClear>
            <a-select-option v-for="op in packageList" :key="op.id" :value="op.name">
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--#endregion-->
      </ListFilter>
      <!-- 表格 -->
      <ECTable :columns="i.tableColumns" :data-source="i.tableList" bordered showTitle title=" " :loading="loading"
        :pagination="{ ...candidateTabFilters[idx].page }" :scroll="{ x: true }" :row-selection="![1, 3, 4].includes(i.key)
          ? {
            selectedRowKeys: i.selectedIds,
            onChange: (ids, rows) => onSelectChange(ids, rows, idx)
          }
          : {}
          " @change="page => onTableChange(i, page)">
        <!-- 表头操作区 -->
        <div slot="tableHeaderRight" class="button-flex">
          <!-- 委托记录-->
          <template v-if="i.key === 4">
            <!-- 新建委托 -->
            <a-button type="primary" @click="addOrder">
              {{ $t("backCheck.order") }}
            </a-button>
          </template>
          <!-- 导出 -->
          <a-button class="export-btn" @click="handleExport">{{
            $t("button.sexport")
          }}</a-button>
        </div>
        <!-- 委托记录-报告级别 -->
        <template slot="reportLevel" slot-scope="text, record">
          <span class="ec-report-report-level" :class="`ec-report-report-level-${record.reportLevelCode}`"
            :title="record.reportLevelName" />
        </template>
        <!-- 电话 -->
        <template slot="phone" slot-scope="text, record">
          {{
            record.phoneDialingCode
              ? `+${record.phoneDialingCode} ${record.candidateMobileNumber}`
              : record.candidateMobileNumber
          }}
        </template>
        <!-- 候选人姓名 -->
        <template slot="candidateName" slot-scope="text, record">
          <router-link :to="`/message/schedule/${record.taskNo}`">
            {{ record.candidateName }}
          </router-link>
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="text, record">
          <div class="editable-row-operations">
            <a-dropdown :trigger="['click', 'contextmenu']">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ $t("action") }}
              </a>
              <!--#region 易查记录 委托列表-->
              <template v-if="[3, 4].includes(i.key)">
                <a-menu slot="overlay" @click="e => handleMenuClick(e, record, i.key)">
                  <a-menu-item :key="tableActions[3].key">
                    {{ tableActions[3].name }}
                  </a-menu-item>
                  <a-menu-item :key="tableActions[4].key" v-if="
                    [
                      CssTaskProgress[5].value,
                      CssTaskProgress[6].value
                    ].includes(record.taskProgressId)
                  ">
                    {{ tableActions[4].name }}
                  </a-menu-item>
                  <a-menu-item :key="tableActions[5].key" v-if="
                    [
                      CssTaskProgress[0].value,
                      CssTaskProgress[1].value,
                      CssTaskProgress[2].value
                    ].includes(record.taskProgressId) &&
                    record.taskStateId !== 287
                  ">
                    {{ tableActions[5].name }}
                  </a-menu-item>
                  <a-menu-item :key="tableActions[6].key" v-if="i.key === 4 && isGrantGiftBtnVisible(record)">
                    {{ tableActions[6].name }}
                  </a-menu-item>
                </a-menu>
              </template>
              <!--#endregion-->
            </a-dropdown>
          </div>
        </template>
      </ECTable>
    </div>
    <!-- 短信验证 -->
    <SMSModal :isHrModal.sync="isHrModal" :params="exportParams" />
    <!-- 候选人资料管理 modal -->
    <CandidateFileModal :record="fileModalData" :visible.sync="fileModalVisible" />
    <StopCheckModal :isShowModal.sync="isStopModal" :record="currentItem" @reload="getList" />
    <!--入职礼发放弹窗-->
    <GrantGiftModal :visible.sync="isGrantGiftVisible" />
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import ECTable from "@/components/ECTable";
import SMSModal from "@/pages/home/components/SMSModal";
import Vue from "vue";
import StopCheckModal from "@/pages/home/components/StopCheckModal";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { cloneDeep, trim } from "lodash";
import { orderListPage, orderListSearch } from "@/pages/home/order/api";
import { listDicFormatter, rangeDateFormatter } from "@/utils/tools";
import {
  CssTaskProgress,
  ReportLevel
} from "@/pages/home/order/config";
import { mapGetters, mapState } from "vuex";
import CandidateFileModal from "@/pages/home/components/CandidateFile/Modal.vue";
import GrantGiftModal from "@/pages/workspace/backgroundCheck/gift/Grant/Modal.vue";
import { langMixin } from "@/mixins";

// 背调记录
const columns4 = [
  {
    title: Vue.prototype.$t("backCheck.cname"),
    dataIndex: "candidateName",
    align: "left",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "candidateName" }
  },
  {
    title: Vue.prototype.$t("backCheck.merged.packageName"),
    dataIndex: "templateName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.cidcard"),
    dataIndex: "candidateId",
    align: "left",
    width: 160,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hconcat"),
    dataIndex: "candidateMobileNumber",
    align: "left",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "phone" }
  },
  {
    title: Vue.prototype.$t("backCheck.merged.consignTime"),
    dataIndex: "orderTime",
    align: "center",
    width: 140,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.confirm"),
    dataIndex: "candidateInfoConfirmTime",
    align: "center",
    width: 140,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hprt"),
    dataIndex: "reportTimeStage",
    align: "center",
    width: 150,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.level"),
    dataIndex: "reportLevel",
    align: "center",
    scopedSlots: { customRender: "reportLevel" },
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.number"),
    dataIndex: "taskNumber",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.process"),
    dataIndex: "taskProgressName",
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("backCheck.hcs"),
    dataIndex: "taskStateName",
    align: "center",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("order.filter.hr"),
    dataIndex: "hrUserName",
    align: "left",
    width: 120,
    ellipsis: true
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];

export default {
  name: "CandidateList",
  components: {
    GrantGiftModal,
    CandidateFileModal,
    ListFilter,
    ECTable,
    SMSModal,
    StopCheckModal
  },
  mixins: [langMixin],
  data() {
    return {
      tabList: [
        {
          key: 4,
          title: this.$t("backCheck.merged.tabTitle"),
          filterList: [
            {
              label: this.$t("backCheck.cname"),
              key: "candidateName",
              type: "input"
            },
            {
              label: this.$t("backCheck.merged.packageName"),
              key: "templateName",
              type: "input"
            },
            {
              label: this.$t("backCheck.merged.consignTime"),
              key: "consignTime",
              type: "timeRange"
            },
            {
              label: this.$t("order.filter.confirm"),
              key: "confirmTime",
              type: "timeRange"
            },
            {
              label: this.$t("order.filter.level"),
              key: "reportLevelId",
              type: "select",
              options: ReportLevel
            },
            {
              label: this.$t("order.filter.number"),
              key: "taskNumber",
              type: "input"
            },
            {
              label: this.$t("order.filter.process"),
              key: "taskProgressId",
              type: "select",
              options: CssTaskProgress
            },
            {
              label: this.$t("order.filter.hr"),
              slotName: "targetUserId",
              type: "slot",
              key: "targetUserId"
            }
          ],
          tableTitle: this.$t("backCheck.hc"),
          tableList: [],
          tableColumns: columns4,
          selectedRows: [],
          selectedIds: []
        }
      ],
      tableActions: [
        {
        },
        {
        },
        {
        },
        // 3 资料管理
        {
          key: 4,
          name: this.$t("order.table.data")
        },
        // 4 报告详情
        {
          key: 5,
          name: this.$t("order.table.detail")
        },
        // 5 停止核实
        {
          key: 6,
          name: this.$t("order.table.stop")
        },
        // 6 发放入职礼
        {
          key: 7,
          name: this.$t("order.gift.grant")
        }
      ],
      dcVisible: false,
      isHrModal: false,
      exportParams: {},
      fileModalVisible: false,
      fileModalData: {},
      isStopModal: false,
      isGrantGiftVisible: false,
      currentIndex: "",
      loading: false,
      currentItem: {},
      fileList: [],
      currentFilterData: {},
      CssTaskProgress: cloneDeep(CssTaskProgress),
      hrList: [],
      registrationSourceList: [],
      guidanceShow1: false,
      packageList: []
    };
  },
  computed: {
    currentTabKey: {
      get() {
        return this.$store.state.back.candidateTab;
      },
      set(newValue) {
        this.$store.commit("back/setCandidateTab", newValue);
        this.currentIndex = this.tabList.findIndex(i => i.key === newValue);
      }
    },
    ...mapState({
      candidateTabFilters: state => state.back.candidateTabFilters
    }),
    ...mapGetters("user", ["multipleCompany", "companyList"])
  },
  methods: {
    // tab 改变
    async tabChange(key) {
      this.currentTabKey = key;
      const idx = this.tabList.findIndex(it => it.key === key);
      this.candidateTabFilters[idx].filters = this.formatEntrustListParams(
        this.candidateTabFilters[idx].filters
      );
      this.currentFilterData = {
        ...this.candidateTabFilters[idx].filters
      };
      await this.getList(key);
    },
    // 获取列表
    async getList(currentTabKey) {
      this.loading = true;

      switch (currentTabKey || this.currentTabKey) {
        case 4:
          await this.getEntrustList();
          break;
      }
      this.loading = false;
    },
    // 菜单点击事件
    async handleMenuClick(e, item, tabKey) {
      this.currentItem = item;
      const currMenu = this.tableActions.find(it => it.key === e.key);

      // 资料管理
      if (e.key === this.tableActions[3].key) {
        this.openFiles(item);
        return;
      }
      // 报告详情
      if (e.key === this.tableActions[4].key) {
        this.$router.push({
          name: "OrderReport",
          params: {
            id: this.currentItem.taskNo,
            status: item.taskProgressId
          }
        });
        return;
      }
      // 停止核实
      if (e.key === this.tableActions[5].key) {
        if (item.sourceId && item.sourceId === 814) {
          showMsg({ flag: 2, title: this.$t('order.table.stop'), msg: this.$t("member.bind.feishu.cancelOperateBlockedMessage") });
          return;
        }

        this.isStopModal = true;
        return;
      }
      // 发放入职礼
      if (e.key === this.tableActions[6].key) {
        this.isGrantGiftVisible = true;
        return;
      }

      this.$router.push({
        name: currMenu?.routerName,
        params: { type: "single" },
        query: { selected: "1" }
      });
    },
    // 获取候选人资料
    async openFiles(item) {
      this.fileModalData = {
        taskId: item.taskNo,
        candidateName: item.candidateName,
        // 报告编号
        reportNumbering: item.taskNumber
      };
      this.fileModalVisible = true;
    },
    onResetFilter(idx) {
      this.$store.commit("back/setCandidateTabFilters", {
        index: idx,
        filters: false
      });
      this.currentFilterData = {};
    },
    // 查询事件
    onFilter(key, form, isSkipRequest) {
      this.currentFilterData = { ...form };
      switch (key) {
        // 委托记录
        case 4:
          this.$store.commit("back/setCandidateTabFilters", {
            index: 0,
            filters: this.formatEntrustListParams(form),
            // fix#https://echeck-china.coding.net/p/echeck-platform/requirements/issues/204/detail
            pageCurrent: 1
          });
          break;
        default:
          break;
      }

      if (isSkipRequest) {
        return;
      }

      this.getList();
    },
    // 复选框选中事件
    onSelectChange(selection, selectRows, idx) {
      this.tabList[idx].selectedIds = selection;
      this.tabList[idx].selectedRows = selectRows;
    },
    // 新建委托
    addOrder() {
      this.$router.push({ name: "BackOrderAdd" });
    },
    // 导出
    handleExport() {
      this.exportParams = {
        size: this.candidateTabFilters[this.currentIndex].page.pageSize,
        index: this.candidateTabFilters[this.currentIndex].page.current,
        ...this.currentFilterData
      };
      if (this.currentTabKey === 4) {
        this.exportParams = this.formatEntrustListParams(this.exportParams);
        this.exportParams.consignTime = undefined;
        this.exportParams.confirmTime = undefined;
      }
      this.isHrModal = true;
    },
    // 委托列表入参格式化
    formatEntrustListParams(params) {
      return {
        ...params,
        // 委托时间起始
        createOrderDateBegin: rangeDateFormatter(params.consignTime).start,
        // 委托时间截止
        createOrderDateEnd: rangeDateFormatter(params.consignTime).end,
        // 信息确认时间起始
        collectionTableConfirmDateBegin: rangeDateFormatter(params.confirmTime)
          .start,
        // 信息确认时间截止
        collectionTableConfirmDateEnd: rangeDateFormatter(params.confirmTime)
          .end,
        // 候选人姓名
        candidateName: trim(params.candidateName),
        // 报告编号
        taskNumber: trim(params.taskNumber)
      };
    },
    // 委托列表 - 已经和易查列表合并
    async getEntrustList() {
      const { filters } = this.candidateTabFilters[this.currentIndex];
      const params = {
        size: this.candidateTabFilters[this.currentIndex].page.pageSize,
        index: this.candidateTabFilters[this.currentIndex].page.current,
        ...filters,
        consignTime: undefined,
        confirmTime: undefined
      };
      const res = await orderListPage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      let list = res.data?.list || [];
      list.forEach(it => {
        it.key = it.orderId;
      });
      list = await listDicFormatter(list, [
        { code: CssTaskProgress, key: "taskProgressNo", local: true }
      ]);
      this.tabList[this.currentIndex].tableList = list;
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageTotal: res.data.total
      });
    },
    onTableChange(tab, page) {
      this.$store.commit("back/setCandidateTabFilters", {
        index: this.currentIndex,
        pageCurrent: page.current,
        pageSize: page.pageSize
      });
      this.getList();
    },
    // HR列表
    async getStaff() {
      const res = await orderListSearch({});
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      this.hrList = res.data?.user || [];
      this.packageList = res.data?.packages || [];
    },
    // 发放入职礼按钮是否显示
    isGrantGiftBtnVisible(record) {
      return false;
      // 变更状态为空、终版报告、入职礼字段为空或者暂不发放时，显示“发放入职礼”按钮
      // return (
      //   !record.changeState &&
      //   record.taskProgressId === CssTaskProgress[6].dictCode &&
      //   !record.joinGift
      // );
    },
    // 获取当前页面的操作指引
    getGuidance() {
      this.$store.dispatch("guidance/getRead", { key: "1" }).then(read => {
        this.guidanceShow1 = !read;
      });
    },
    // 关闭操作指引
    onGuidanceClose(key) {
      this.$store.commit("guidance/setRead", {
        key,
        userId: this.$store.state.user.info.userId
      });
      this.getGuidance();
    }
  },
  mounted() {
    this.getGuidance();

    if (this.multipleCompany) {
      this.tabList.forEach(tabItem => {
        if ([1, 2].includes(tabItem.key)) {
          return;
        }
        tabItem.filterList.unshift({
          label: this.$t("bill.filter.org"),
          slotName: "companyId",
          type: "slot"
        });
      });
    }
    this.getStaff();
    this.tabChange(this.currentTabKey);
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-tabs-bar {
  margin-bottom: 0;
}

.candidate-list {
  background-color: #fff;
  min-height: 100%;

  .tab-item {
    padding: @ec-gutter * 2;
    padding-top: 0;
  }

  .edit-select {
    width: 120px;
  }

  .list-filter {
    /deep/.list-card {
      border-bottom: 1px solid #e9e9e9;
    }
  }
}

/deep/.list-card .list-header .list-title {
  margin-bottom: 12px !important;
}

.export-btn {
  background: @secondary-color;
  color: #fff;

  &:hover,
  &:focus {
    border: 1px solid @secondary-color;
  }
}

.authorize-modal {
  /deep/.ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  /deep/.icon-tip {
    width: 70px;
    height: 70px;
    margin-top: 70px;
    margin-bottom: 18px;
  }

  .authorize-title {
    font-size: 18px;
    color: @font-color;
    text-align: center;
  }

  .authorize-text {
    font-size: 18px;
    color: @font-color;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .authorize-tip {
    color: @sub-color;
    font-size: @font-size-large;
  }

  .authorize-method {
    display: flex;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 20px;
        .pointer();
      }

      &-text {
        font-size: @font-size-large;
        color: @font-color;
        margin-left: 8px;
        margin-right: @ec-gutter20;
      }
    }
  }
}

.button-flex {
  display: flex;
  gap: @ec-gutter20;
  flex-wrap: nowrap;
}

/deep/.can-manage-list-filter {
  .filter-form-inner {

    .ant-col:nth-child(2),
    .ant-col:nth-child(5),
    .ant-col:nth-child(8) {
      .ant-form-item-label {
        width: 40%;
      }
    }
  }
}
</style>
