var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"candidate-list"},[_vm._l((_vm.tabList),function(i,idx){return _c('div',{key:i.key},[_c('ListFilter',{staticClass:"list-filter can-manage-list-filter",attrs:{"form-data":_vm.currentFilterData,"form-list":i.filterList,"title":''},on:{"update:formData":function($event){_vm.currentFilterData=$event},"update:form-data":function($event){_vm.currentFilterData=$event},"reset":() => _vm.onResetFilter(idx),"filter":form => _vm.onFilter(i.key, form)}},[_c('a-form-model-item',{attrs:{"slot":"companyId","label":_vm.$t('bill.filter.org')},slot:"companyId"},[_c('a-select',{attrs:{"getPopupContainer":trigger => trigger.parentNode,"size":"large","value":_vm.candidateTabFilters[idx].filters.targetCompanyId,"placeholder":_vm.$t('bill.filter.org'),"allowClear":""},on:{"change":companyId =>
            _vm.onFilter(
              i.key,
              { ..._vm.currentFilterData, targetCompanyId: companyId },
              true
            )}},_vm._l((_vm.companyList),function(op){return _c('a-select-option',{key:op.companyId,attrs:{"value":op.companyId}},[_vm._v(" "+_vm._s(op.name)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"slot":"targetUserId","label":_vm.$t('order.filter.hr')},slot:"targetUserId"},[_c('a-select',{attrs:{"show-search":"","filter-option":(input, option) => {
          return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
          )
        },"getPopupContainer":trigger => trigger.parentNode,"size":"large","value":_vm.candidateTabFilters[idx].filters.targetUserId,"placeholder":_vm.$t('order.filter.hr'),"allowClear":""},on:{"change":targetUserId =>
            _vm.onFilter(i.key, { ..._vm.currentFilterData, targetUserId }, true)}},_vm._l((_vm.hrList),function(op){return _c('a-select-option',{key:op.id,attrs:{"value":op.id}},[_vm._v(" "+_vm._s(op.name)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"slot":"registrationSource","label":_vm.$t('backCheck.registrationSource')},slot:"registrationSource"},[_c('a-select',{attrs:{"getPopupContainer":trigger => trigger.parentNode,"size":"large","value":_vm.candidateTabFilters[idx].filters.registrationSource,"placeholder":`${_vm.$t('select')}${_vm.$t('backCheck.registrationSource')}`,"allowClear":""},on:{"change":registrationSource =>
            _vm.onFilter(
              i.key,
              { ..._vm.currentFilterData, registrationSource },
              true
            )}},_vm._l((_vm.registrationSourceList),function(op){return _c('a-select-option',{key:op.key,attrs:{"value":op.key}},[_vm._v(" "+_vm._s(op.value)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"slot":"packageName","label":_vm.$t('backCheck.merged.packageName')},slot:"packageName"},[_c('a-select',{attrs:{"getPopupContainer":trigger => trigger.parentNode,"size":"large","value":_vm.candidateTabFilters[idx].filters.packageName,"placeholder":_vm.$t('backCheck.merged.packageName'),"allowClear":""},on:{"change":packageName =>
            _vm.onFilter(i.key, { ..._vm.currentFilterData, packageName }, true)}},_vm._l((_vm.packageList),function(op){return _c('a-select-option',{key:op.id,attrs:{"value":op.name}},[_vm._v(" "+_vm._s(op.name)+" ")])}),1)],1)],1),_c('ECTable',{attrs:{"columns":i.tableColumns,"data-source":i.tableList,"bordered":"","showTitle":"","title":" ","loading":_vm.loading,"pagination":{ ..._vm.candidateTabFilters[idx].page },"scroll":{ x: true },"row-selection":![1, 3, 4].includes(i.key)
        ? {
          selectedRowKeys: i.selectedIds,
          onChange: (ids, rows) => _vm.onSelectChange(ids, rows, idx)
        }
        : {}},on:{"change":page => _vm.onTableChange(i, page)},scopedSlots:_vm._u([{key:"reportLevel",fn:function(text, record){return [_c('span',{staticClass:"ec-report-report-level",class:`ec-report-report-level-${record.reportLevelCode}`,attrs:{"title":record.reportLevelName}})]}},{key:"phone",fn:function(text, record){return [_vm._v(" "+_vm._s(record.phoneDialingCode ? `+${record.phoneDialingCode} ${record.candidateMobileNumber}` : record.candidateMobileNumber)+" ")]}},{key:"candidateName",fn:function(text, record){return [_c('router-link',{attrs:{"to":`/message/schedule/${record.taskNo}`}},[_vm._v(" "+_vm._s(record.candidateName)+" ")])]}},{key:"action",fn:function(text, record){return [_c('div',{staticClass:"editable-row-operations"},[_c('a-dropdown',{attrs:{"trigger":['click', 'contextmenu']}},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":e => e.preventDefault()}},[_vm._v(" "+_vm._s(_vm.$t("action"))+" ")]),([3, 4].includes(i.key))?[_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":e => _vm.handleMenuClick(e, record, i.key)},slot:"overlay"},[_c('a-menu-item',{key:_vm.tableActions[3].key},[_vm._v(" "+_vm._s(_vm.tableActions[3].name)+" ")]),(
                  [
                    _vm.CssTaskProgress[5].value,
                    _vm.CssTaskProgress[6].value
                  ].includes(record.taskProgressId)
                )?_c('a-menu-item',{key:_vm.tableActions[4].key},[_vm._v(" "+_vm._s(_vm.tableActions[4].name)+" ")]):_vm._e(),(
                  [
                    _vm.CssTaskProgress[0].value,
                    _vm.CssTaskProgress[1].value,
                    _vm.CssTaskProgress[2].value
                  ].includes(record.taskProgressId) &&
                  record.taskStateId !== 287
                )?_c('a-menu-item',{key:_vm.tableActions[5].key},[_vm._v(" "+_vm._s(_vm.tableActions[5].name)+" ")]):_vm._e(),(i.key === 4 && _vm.isGrantGiftBtnVisible(record))?_c('a-menu-item',{key:_vm.tableActions[6].key},[_vm._v(" "+_vm._s(_vm.tableActions[6].name)+" ")]):_vm._e()],1)]:_vm._e()],2)],1)]}}],null,true)},[_c('div',{staticClass:"button-flex",attrs:{"slot":"tableHeaderRight"},slot:"tableHeaderRight"},[(i.key === 4)?[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addOrder}},[_vm._v(" "+_vm._s(_vm.$t("backCheck.order"))+" ")])]:_vm._e(),_c('a-button',{staticClass:"export-btn",on:{"click":_vm.handleExport}},[_vm._v(_vm._s(_vm.$t("button.sexport")))])],2)])],1)}),_c('SMSModal',{attrs:{"isHrModal":_vm.isHrModal,"params":_vm.exportParams},on:{"update:isHrModal":function($event){_vm.isHrModal=$event},"update:is-hr-modal":function($event){_vm.isHrModal=$event}}}),_c('CandidateFileModal',{attrs:{"record":_vm.fileModalData,"visible":_vm.fileModalVisible},on:{"update:visible":function($event){_vm.fileModalVisible=$event}}}),_c('StopCheckModal',{attrs:{"isShowModal":_vm.isStopModal,"record":_vm.currentItem},on:{"update:isShowModal":function($event){_vm.isStopModal=$event},"update:is-show-modal":function($event){_vm.isStopModal=$event},"reload":_vm.getList}}),_c('GrantGiftModal',{attrs:{"visible":_vm.isGrantGiftVisible},on:{"update:visible":function($event){_vm.isGrantGiftVisible=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }